




















































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';

import EmailForm from '@improve/common-components/src/components/forms/EmailForm.vue';
import BaseCenteredCard
  from '@improve/common-components/src/components/widgets/BaseCenteredCard.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import TermsAndConditionsForm
  from '@improve/common-components/src/components/forms/TermsAndConditionsForm.vue';
import UIStates from '@improve/common-utils/src/types/UIStates';
import SSO_MODE from '@improve/common-utils/src/model/SsoMode';

@Component({
  name: 'Register',
  components: {
    BaseCenteredCard,
    BaseButton,
    EmailForm,
    TermsAndConditionsForm
  }
})
export default class Register extends Vue {
  @Action register!: ActionMethod;

  @Action fetchOrgSsoMode!: ActionMethod;

  email: string | null = null;

  invitedEmail: string | null = null;

  errorHappened = false;

  uiStates = UIStates;

  currentUIState: string | null = null;

  language = 'en';

  tcDocLinks: { ppLink: string | null; tcLink: string | null } | null = null;

  created(): void {
    if (this.$route.params.demo) {
      this.currentUIState = UIStates.ORGANIZATION_NOT_FOUND;
    } else {
      this.currentUIState = UIStates.SUBMIT_EMAIL;
    }
    this.checkUserInvitation();
  }

  checkUserInvitation(): void {
    if (this.$route.query.email) {
      const email = this.$route.query.email as string;
      this.invitedEmail = email;
      setTimeout(() => this.submitEmail({ email }), 100);
    }
  }

  async submitEmail(event: { email: string }): Promise<void> {
    if (!(await this.validateAndSubmit())) {
      return;
    }
    this.email = event.email;
    let ssoMode;
    try {
      ssoMode = await this.fetchOrgSsoMode(this.email);
    } catch (e) {
      // Company not found
    }
    if (ssoMode === SSO_MODE.SAML_ONLY) {
      this.$router.push({
        name: 'LoginSSO',
        params: {
          email: `${this.email}`
        }
      });
    } else if (!ssoMode) {
      this.currentUIState = UIStates.ORGANIZATION_NOT_FOUND;
    } else {
      this.currentUIState = UIStates.ACCEPT_TC;
    }
  }

  async registerEmail(): Promise<void> {
    this.register({
      email: this.email,
      language: this.language
    }).then(() => {
      this.currentUIState = UIStates.CONFIRMATION;
    }).catch(() => {
      this.currentUIState = UIStates.ERROR_OCCURRED;
    });
  }

  validateAndSubmit(): Promise<boolean> {
    if (this.$refs.obs) {
      const comp: any = this.$refs.obs;
      return comp.validate();
    }
    return Promise.resolve(false);
  }
}
